<template>
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30 52.5H13.5L9 64.5H30C30.8285 64.5 31.5 63.8284 31.5 63V54C31.5 53.1715 30.8285 52.5 30 52.5Z"
      fill="#4DA34D"
      style="fill: #4da34d; fill: color(display-p3 0.302 0.6392 0.302); fill-opacity: 1" />
    <path
      d="M70.5 54.0001L59.6526 30.8907C59.3003 30.1302 58.3932 29.806 57.6387 30.1708L49.3129 34.1962C48.5739 34.5535 48.2593 35.4383 48.607 36.1819L57.4219 55.0313L70.5 54.0001Z"
      fill="#4DA34D"
      style="fill: #4da34d; fill: color(display-p3 0.302 0.6392 0.302); fill-opacity: 1" />
    <path
      d="M45.1856 68.9978L36.4621 59.0281C36.202 58.731 36.217 58.2831 36.4962 58.0039L45.2197 49.2804C45.6922 48.8079 46.5 49.1425 46.5 49.8107V54.0001H70.5L64.9319 63.7443C64.6648 64.2116 64.1678 64.5001 63.6295 64.5001H46.5V68.504C46.5 69.1965 45.6416 69.519 45.1856 68.9978Z"
      fill="#72C472"
      style="fill: #72c472; fill: color(display-p3 0.4471 0.7686 0.4471); fill-opacity: 1" />
    <path
      d="M5.37562 36.1037L17.6418 31.9025C18.0293 31.7697 18.4516 31.9725 18.5904 32.3579L23.0384 44.7089C23.2642 45.3359 22.6211 45.924 22.0167 45.6431L18.1311 43.8374L8.99995 64.5L2.00866 54.7122C1.69432 54.2721 1.6412 53.6969 1.86965 53.2067L8.50021 38.9794L5.30257 37.4934C4.68991 37.2087 4.73648 36.3226 5.37562 36.1037Z"
      fill="#72C472"
      style="fill: #72c472; fill: color(display-p3 0.4471 0.7686 0.4471); fill-opacity: 1" />
    <path
      d="M26.4728 1.52429L15.0585 23.5559C14.668 24.3107 14.982 25.2388 15.7505 25.6015L24.0873 29.5366C24.8216 29.8833 25.6985 29.5826 26.0655 28.8582L35.0625 11.0625L26.4728 1.52429Z"
      fill="#4DA34D"
      style="fill: #4da34d; fill: color(display-p3 0.302 0.6392 0.302); fill-opacity: 1" />
    <path
      d="M54.0348 15.1362L51.1634 27.78C51.0727 28.1795 50.6782 28.4323 50.2774 28.3478L37.432 25.6408C36.7798 25.5034 36.6175 24.6472 37.174 24.2806L40.7523 21.9235L26.4727 1.52429L40.8659 1.52632C41.4106 1.52639 41.9125 1.82173 42.177 2.29789L49.9465 16.2838L52.8907 14.3438C53.4549 13.9721 54.1844 14.4773 54.0348 15.1362Z"
      fill="#72C472"
      style="fill: #72c472; fill: color(display-p3 0.4471 0.7686 0.4471); fill-opacity: 1" />
  </svg>
</template>
